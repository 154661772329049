import { mapActions, mapGetters } from 'vuex'
import L from 'leaflet'
import Autolinker from 'autolinker'
import ADMIN_3_1 from '../json/ADMIN_3_1.json'
import ADMIN_2_2 from '../json/ADMIN_2_2.json'
import ADMIN_1_3 from '../json/ADMIN_1_3.json'
import TERGROMADA_4 from '../json/Tergromada_4.json'
import RAYON_5 from '../json/Rayon_5.json'
import OBLAST_6 from '../json/Oblast_6.json'
import CAPITAL_7 from '../json/Capital_7.json'

export default {
  data: () => ({
    windowSize: {},
    center: [46.36193746632452, 33.760257729627405],
    map: null,
    bounds_group: null,
    autolinker: null,
    modal: false,
    modalData: null,
    searchSettlements: '',
    checkedSettlements: {
      rent: {},
      sell: {},
      stat: {}
    },
    selectedSettlements: [],
    limitSettlements: 15,
    selectAll: {
      rent: {},
      sell: {},
      stat: {}
    },
    settlements: {},
    tableSettlementsAmount: 0,
    stylesForSelectedFeatures: {
      fillColor: 'rgba(0,196,83,0.73)',
      fillOpacity: 0.8
    },
    currentLayer: null,
    isLoading: true,
    selectedTGCoordinates: null,
    stylesForSelectedTG: {
      fillColor: 'rgb(222,218,6)',
      fillOpacity: 0.8
    }
  }),
  computed: {
    ...mapGetters('map', ['getSettlements', 'getSubscribes']),
    ...mapGetters('auth', ['isAuthed'])
  },
  watch: {
    'modalData.settlements' () {
      this.beforeInitializationTables()
    }
  },
  methods: {
    ...mapActions('map', [
      'fetchSettlements',
      'subscribeOnLands',
      'fetchSubscribes'
    ]),
    beforeInitializationTables () {
      if (this.windowSize.x < 876) {
        this.limitSettlements = 300
        this.tableSettlementsAmount = 1
      } else {
        this.limitSettlements = 15
        if (this.modalData && this.modalData.settlements) {
          const length = this.modalData.settlements.length
          this.tableSettlementsAmount = Math.ceil(
            length / this.limitSettlements
          )
        } else {
          this.tableSettlementsAmount = 1
        }
      }
    },
    handlerCheckedSettlement (id, type) {
      const otgCodificator = this.modalData.COD_3
      const existSettlement = this.selectedSettlements.findIndex(
        (el) => el.otgCodificator === otgCodificator
      )
      const obj = {
        codificator: id
      }
      if (!this.checkedSettlements[type][id]) {
        this.selectAll[type][otgCodificator] = false
      }
      obj[type] = this.checkedSettlements[type][id]
      if (existSettlement !== -1) {
        const find = this.selectedSettlements[
          existSettlement
          ].subscribes.findIndex((el) => el.codificator === id)
        if (find !== -1) {
          this.selectedSettlements[existSettlement].subscribes[find][type] =
            this.checkedSettlements[type][id]
        } else {
          this.selectedSettlements[existSettlement].subscribes.push(obj)
        }
      } else {
        this.selectedSettlements.push({
          otgCodificator,
          subscribes: [obj]
        })
      }
      localStorage.selectedSettlements = JSON.stringify(
        this.selectedSettlements
      )
      this.saveCheckedSettlementsToStorage()
    },
    saveCheckedSettlementsToStorage () {
      if (
        this.checkedSettlements.rent ||
        this.checkedSettlements.sell ||
        this.checkedSettlements.stat
      ) {
        localStorage.checkedSettlements = JSON.stringify(
          this.checkedSettlements
        )
      }
    },
    handlerSearch () {
      if (this.searchSettlements) {
        this.modalData.settlements = this.settlements.filter(
          (el) =>
            el.name
              .toLowerCase()
              .indexOf(this.searchSettlements.toLowerCase()) !== -1
        )
      } else {
        this.modalData.settlements = this.settlements
      }
      const length = this.modalData.settlements.length
      this.tableSettlementsAmount = Math.ceil(length / this.limitSettlements)
    },
    handlerSelectAll (type) {
      const otgCodificator = this.modalData.COD_3
      const data = {
        otgCodificator,
        subscribes: []
      }
      this.modalData.settlements.forEach((el) => {
        const obj = {
          codificator: el.id
        }
        if (this.selectAll[type][otgCodificator]) {
          this.checkedSettlements[type][el.id] = true
          obj[type] = true
        } else {
          delete this.checkedSettlements[type][el.id]
          obj[type] = false
        }
        if (type === 'rent') {
          obj.sell = Boolean(this.selectAll.sell[otgCodificator])
          obj.stat = Boolean(this.selectAll.stat[otgCodificator])
        } else if (type === 'stat') {
          obj.sell = Boolean(this.selectAll.sell[otgCodificator])
          obj.rent = Boolean(this.selectAll.rent[otgCodificator])
        } else if (type === 'sell') {
          obj.stat = Boolean(this.selectAll.stat[otgCodificator])
          obj.rent = Boolean(this.selectAll.rent[otgCodificator])
        }
        data.subscribes.push(obj)
      })
      const indexOfExistSettlement = this.selectedSettlements.findIndex(
        (el) => el.otgCodificator === otgCodificator
      )
      if (indexOfExistSettlement !== -1) {
        this.selectedSettlements[indexOfExistSettlement].subscribes =
          data.subscribes
      } else {
        this.selectedSettlements.push(data)
      }
      localStorage.selectedSettlements = JSON.stringify(
        this.selectedSettlements
      )
      this.saveCheckedSettlementsToStorage()
    },
    handlerSaveBtn () {
      if (this.isAuthed) {
       /* console.log(Object.entries(this.checkedSettlements))
        console.log(this.$route.query.selectedFeature)
        Object.entries(this.checkedSettlements).forEach(([key, val]) => {
          console.log({ key, val })
        })
        console.log(this.selectedSettlements)*/
        this.modal = false
        this.subscribeOnLands(this.selectedSettlements).then(() => {
          this.modal = false
          this.$success({
            text: '<span class="d-flex align-center">Підписка здійснена успішно<i class="mdi mdi-check font-16 ml-4"></i></span>'
          })
          this.$router.replace({ query: {} }).catch(() => {
          })
          if (
            this.selectedSettlements.find(
              (el) => el.otgCodificator === this.modalData.COD_3
            )
          ) {
            this.currentLayer &&
            this.currentLayer.setStyle(this.stylesForSelectedFeatures)
            this.$store.commit('map/pushToSubscribes', this.modalData.COD_3)
            this.fetchSubscribes()
          }
          localStorage.removeItem('checkedSettlements')
          localStorage.removeItem('selectedSettlements')
          this.selectedSettlements = []
        })
      } else {
        this.$router.push({
          name: 'Login',
          query: { redirect: '/', ...this.$route.query }
        })
      }
    },
    setupLeafletMap () {
      this.map = L.map('mapContainer', {
        zoomControl: true,
        zoomSnap: 0.25,
        maxZoom: 13,
        minZoom: 6.5
      })
      delete L.Icon.Default.prototype._getIconUrl // delete default markets
      const shadowPane = document.querySelector('.leaflet-shadow-pane')
      const markerPane = document.querySelector('.leaflet-marker-pane')
      shadowPane.parentElement.removeChild(shadowPane)
      markerPane.parentElement.removeChild(markerPane)
      this.map.attributionControl.setPrefix(
        '<a href="https://beta.e-vybir.ua/">© Evybir</a>  &middot; <a href="https://leafletjs.com" title="A JS library for interactive maps">Leaflet</a>'
      )
      this.autolinker = new Autolinker({
        truncate: { length: 30, location: 'smart' }
      })
      L.control.locate({ locateOptions: { maxZoom: 19 } }).addTo(this.map)
      this.bounds_group = L.featureGroup([])
      this.map.createPane('pane_OSMStandard_0')
      this.map.getPane('pane_OSMStandard_0').style.zIndex = 1
      const layerOSMStandard0 = L.tileLayer(
        'https://tiles.openstreetmap.org.ua/osm/{z}/{x}/{y}.png',
        {
          pane: 'pane_OSMStandard_0',
          opacity: 1.0,
          attribution:
            '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a> &middot; дані чисельності населення в АРК за 2001 рік',
          minZoom: 6.5,
          maxZoom: 13,
          minNativeZoom: 0,
          maxNativeZoom: 19
        }
      )
      // layer of standart map
      this.map.addLayer(layerOSMStandard0)
      this.map.createPane('pane_ADMIN_3_1')
      this.map.getPane('pane_ADMIN_3_1').style.zIndex = 2
      this.map.getPane('pane_ADMIN_3_1').style['mix-blend-mode'] = 'normal'
      const layerADMIN3 = L.geoJSON(ADMIN_3_1, {
        attribution: '',
        interactive: true,
        dataVar: 'json_ADMIN_3_1',
        layerName: 'layerADMIN3',
        pane: 'pane_ADMIN_3_1',
        onEachFeature: this.pop_ADMIN_3_1,
        style: this.style_ADMIN_3_1_0
      })
      this.bounds_group.addLayer(layerADMIN3)
      this.map.addLayer(layerADMIN3)
      this.map.createPane('pane_ADMIN_2_2')
      this.map.getPane('pane_ADMIN_2_2').style.zIndex = 3
      this.map.getPane('pane_ADMIN_2_2').style['mix-blend-mode'] = 'normal'
      const layerADMIN2 = L.geoJSON(ADMIN_2_2, {
        attribution: '',
        interactive: false,
        dataVar: 'json_ADMIN_2_2',
        layerName: 'layer_ADMIN_2_2',
        pane: 'pane_ADMIN_2_2',
        onEachFeature: this.pop_ADMIN_2_2,
        style: this.style_ADMIN_2_2_0
      })
      // layer of district boundaries
      // this.bounds_group.addLayer(layerADMIN2)
      // this.map.addLayer(layerADMIN2)
      this.map.createPane('pane_ADMIN_1_3')
      this.map.getPane('pane_ADMIN_1_3').style.zIndex = 4
      this.map.getPane('pane_ADMIN_1_3').style['mix-blend-mode'] = 'normal'
      const layerADMIN1 = L.geoJSON(ADMIN_1_3, {
        attribution: '',
        interactive: true,
        dataVar: 'json_ADMIN_1_3',
        layerName: 'layer_ADMIN_1_3',
        pane: 'pane_ADMIN_1_3',
        onEachFeature: this.pop_ADMIN_1_3,
        style: this.style_ADMIN_1_3_0
      })
      // layer of boundaries of areas
      this.bounds_group.addLayer(layerADMIN1)
      this.map.addLayer(layerADMIN1)
      this.map.createPane('pane_Tergromada_4')
      this.map.getPane('pane_Tergromada_4').style.zIndex = 5
      this.map.getPane('pane_Tergromada_4').style['mix-blend-mode'] = 'normal'
      const layerTergromada4 = L.geoJSON(TERGROMADA_4, {
        attribution: '',
        interactive: true,
        dataVar: 'json_Tergromada_4',
        layerName: 'layer_Tergromada_4',
        pane: 'pane_Tergromada_4',
        onEachFeature: this.pop_Tergromada_4,
        // pointToLayer: this.extraFuncTergromada
        pointToLayer: function (feature, latlng) {
          return L.circleMarker(latlng, {
            pane: 'pane_Tergromada_4',
            radius: 3.599999999999998,
            opacity: 1,
            color: 'rgba(35,35,35,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(255,255,255,1.0)',
            interactive: true
          })
        }
      })
      // layer of tergromad centers
      /*this.bounds_group.addLayer(layerTergromada4)
      this.map.addLayer(layerTergromada4)*/
      this.map.createPane('pane_Rayon_5')
      this.map.getPane('pane_Rayon_5').style.zIndex = 6
      this.map.getPane('pane_Rayon_5').style['mix-blend-mode'] = 'normal'
      const layerRayon5 = L.geoJSON.multiStyle(RAYON_5, {
        attribution: '',
        interactive: true,
        dataVar: 'json_Rayon_5',
        layerName: 'layer_Rayon_5',
        pane: 'pane_Rayon_5',
        onEachFeature: this.pop_Rayon_5,
        pointToLayers: [this.extraFuncRayon_5_0, this.extraFuncRayon_5_1]
      })
      // layer of district centers
      // this.bounds_group.addLayer(layerRayon5)
      // this.map.addLayer(layerRayon5)
      this.map.createPane('pane_Oblast_6')
      this.map.getPane('pane_Oblast_6').style.zIndex = 7
      this.map.getPane('pane_Oblast_6').style['mix-blend-mode'] = 'normal'
      const layerOblast6 = L.geoJSON.multiStyle(OBLAST_6, {
        attribution: '',
        interactive: true,
        dataVar: 'json_Oblast_6',
        layerName: 'layer_Oblast_6',
        pane: 'pane_Oblast_6',
        onEachFeature: this.pop_Oblast_6,
        pointToLayers: [this.extraFuncOblast_6_0, this.extraFuncOblast_6_1]
      })
      // layer of centers of regions and cities special status
      /*this.bounds_group.addLayer(layerOblast6)
      this.map.addLayer(layerOblast6)*/
      this.map.createPane('pane_Capital_7')
      this.map.getPane('pane_Capital_7').style.zIndex = 8
      this.map.getPane('pane_Capital_7').style['mix-blend-mode'] = 'normal'
      const layerCapital7 = L.geoJSON.multiStyle(CAPITAL_7, {
        attribution: '',
        interactive: true,
        dataVar: 'json_Capital_7',
        layerName: 'layer_Capital_7',
        pane: 'pane_Capital_7',
        onEachFeature: this.pop_Capital_7,
        pointToLayers: [this.extraFuncCapital_7_0, this.extraFuncCapital_7_1]
      })
      /*this.bounds_group.addLayer(layerCapital7)
      this.map.addLayer(layerCapital7)*/
      new L.Control.Geocoder({
        position: 'topleft',
        text: 'Пошук по індексу / назві нас.пункту',
        placeholder: 'Пошук по індексу / назві нас.пункту'
      }).addTo(this.map)
      var baseMaps = {}
      L.control
        .layers(baseMaps, {
          '<img src="img/legend/Capital_7.png" /> Столиця України':
          layerCapital7,
          '<img src="img/legend/Oblast_6.png" /> Центри областей та міст спец. статусу':
          layerOblast6,
          '<img src="img/legend/Rayon_5.png" /> Центри районів': layerRayon5,
          '<img src="img/legend/Tergromada_4.png" /> Центри тергромад':
          layerTergromada4,
          '<img src="img/legend/ADMIN_1_3.png" /> Межі областей': layerADMIN1,
          '<img src="img/legend/ADMIN_2_2.png" /> Межі районів': layerADMIN2,
          '<img src="img/legend/ADMIN_3_1.png" /> Територіальні громади':
          layerADMIN3
        })
        .addTo(this.map)
      this.setBounds()
      this.map.addControl(
        new L.Control.Search({
          layer: layerADMIN3,
          initial: false,
          hideMarkerOnCollapse: true,
          propertyName: 'ADMIN_3',
          textPlaceholder: 'Пошук по ТГ',
          delayType: 0,
          zoom: 10,
          marker: {
            circle: {
              radius: 15,
              weight: 3,
              color: '#49003e',
              stroke: true,
              fill: true
            }
          }
        })
      )
    },
    highlightFeature (e) {
      const highlightLayer = e.target
      const color = '#00c453b8'
      if (e.target.feature.geometry.type === 'LineString') {
        highlightLayer.setStyle({
          color
        })
      } else {
        if (
          this.getSubscribes.includes(highlightLayer.feature.properties.COD_3)
        ) {
          highlightLayer.setStyle(this.stylesForSelectedFeatures)
        } else {
          highlightLayer.setStyle({
            fillColor: color,
            fillOpacity: 0.4
          })
        }
      }
    },
    setBounds () {
      if (this.selectedTGCoordinates) {
        this.map.fitBounds([this.selectedTGCoordinates])
        this.map.setZoom(10)
      } else if (this.bounds_group.getLayers().length) {
        this.map.fitBounds(this.bounds_group.getBounds())
      }
    },
    pop_ADMIN_3_1 (feature, layer) {
      layer.on({
        mouseout: function (e) {
          for (var i in e.target._eventParents) {
            if (
              this.getSubscribes.includes(e.target.feature.properties.COD_3)
            ) {
              e.target.setStyle(this.stylesForSelectedFeatures)
            } else if (
              this.$route.query.selectedTG === e.target.feature.properties.COD_3
            ) {
              layer.setStyle(this.stylesForSelectedTG)
            } else {
              e.target._eventParents[i].resetStyle(e.target)
            }
          }
        }.bind(this),
        mouseover: this.highlightFeature
      })
      if (this.getSubscribes.includes(layer.feature.properties.COD_3)) {
        layer.setStyle(this.stylesForSelectedFeatures)
      } else if (
        this.$route.query.selectedTG === layer.feature.properties.COD_3
      ) {
        layer.setStyle(this.stylesForSelectedTG)
        const coordinates = layer.feature.geometry.coordinates[0][0]
        const coordinate = coordinates[Math.floor(coordinates.length / 2)]
        if (coordinate) {
          this.selectedTGCoordinates = coordinate.reverse()
        } else {
          this.selectedTGCoordinates = null
        }
      }
      const wrapper = document.createElement('div')
      wrapper.className = 'wrap-popup'
      const popupContent = `<table>
                    <tr>
                        <th scope="row">Область</th>
                        <td class="pl-2"> ${
        feature.properties.ADMIN_1 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_1.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Район</th>
                        <td class="pl-2"> ${
        feature.properties.ADMIN_2 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_2.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Назва тергромади</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_3 !== null
          ? `<b>${this.autolinker.link(
            feature.properties.ADMIN_3.toLocaleString()
          )}</b>`
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Тип тергромади</th>
                        <td class="pl-2">${
        feature.properties.TYPE !== null
          ? this.autolinker.link(
            feature.properties.TYPE.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Населення (тис.осіб)</th>
                        <td class="pl-2">${
        feature.properties.population !== null
          ? this.autolinker.link(
            feature.properties.population.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Площа (км.кв)</th>
                        <td class="pl-2">${
        feature.properties.area !== null
          ? this.autolinker.link(
            feature.properties.area.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                     <tr>
                        <th scope="row">КОАТУУ</th>
                        <td class="pl-2">${
        feature.properties.KOATUU_old !== null
          ? this.autolinker.link(
            feature.properties.KOATUU_old.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Кодификатор</th>
                        <td class="pl-2">${
        feature.properties.COD_3 !== null
          ? this.autolinker.link(
            feature.properties.COD_3.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr class="${
        feature.properties.COD_3 !== null
          ? 'link text-decoration-underline'
          : ''
      }">
                        <th scope="row">Всі населені пункти:</th>
                        <td class="pl-2">${
        feature.properties.num_NP !== null
          ? '<span>' +
          this.autolinker.link(
            feature.properties.num_NP.toLocaleString()
          ) +
          '</span>'
          : ''
      }</td>
                        </tr>
                </table>`
      wrapper.innerHTML = popupContent
      this.linkSettlementsHandler(wrapper, feature.properties, layer)
      layer.bindPopup(wrapper, { maxHeight: 600, minWidth: 350, maxWidth: 850 })
      layer.bindTooltip(
        `<table>
                    <tr>
                        <th class="text-left">Область</th>
                        <td class="pl-2"> ${
          feature.properties.ADMIN_1 !== null
            ? this.autolinker.link(
              feature.properties.ADMIN_1.toLocaleString()
            )
            : ''
        }</td>
                    </tr>
                    <tr>
                        <th class="text-left">Район</th>
                        <td class="pl-2"> ${
          feature.properties.ADMIN_2 !== null
            ? this.autolinker.link(
              feature.properties.ADMIN_2.toLocaleString()
            )
            : ''
        }</td>
                    </tr>
                    <tr>
                        <th class="text-left">Назва тергромади</th>
                        <td class="pl-2">${
          feature.properties.ADMIN_3 !== null
            ? `<b>${this.autolinker.link(
              feature.properties.ADMIN_3.toLocaleString()
            )}</b>`
            : ''
        }</td>
                    </tr>`,
        { sticky: true }
      )
    },
    pop_ADMIN_2_2 (feature, layer) {
      layer.on({
        mouseout: function (e) {
          for (var i in e.target._eventParents) {
            e.target._eventParents[i].resetStyle(e.target)
          }
        },
        mouseover: this.highlightFeature
      })
      const popupContent = `<table>
                    <tr>
                        <td class="pl-2" colspan="2">${
        feature.properties.ADMIN_1 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_1.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <td class="pl-2" colspan="2">${
        feature.properties.ADMIN_2 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_2.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <td class="pl-2" colspan="2">${
        feature.properties.COD_2 !== null
          ? this.autolinker.link(
            feature.properties.COD_2.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                </table>`
      layer.bindPopup(popupContent, { maxHeight: 400 })
    },
    pop_ADMIN_1_3 (feature, layer) {
      layer.on({
        mouseout: function (e) {
          for (var i in e.target._eventParents) {
            e.target._eventParents[i].resetStyle(e.target)
          }
        },
        mouseover: this.highlightFeature
      })
      const popupContent = `<table>
                    <tr>
                        <td class="pl-2" colspan="2">${
        feature.properties.ADMIN_1 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_1.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <td class="pl-2" colspan="2">${
        feature.properties.COD_1 !== null
          ? this.autolinker.link(
            feature.properties.COD_1.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                </table>`
      layer.bindPopup(popupContent, { maxHeight: 400 })
    },
    pop_Tergromada_4 (feature, layer) {
      layer.on({
        mouseout: function (e) {
          for (var i in e.target._eventParents) {
            e.target._eventParents[i].resetStyle(e.target)
          }
        },
        mouseover: this.highlightFeature
      })
      const popupContent = `<table>
                    <tr>
                        <th scope="row">Область</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_1 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_1.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Район</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_2 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_2.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Тергромада</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_3 !== null
          ? '<b>' +
          this.autolinker.link(
            feature.properties.ADMIN_3.toLocaleString()
          ) +
          '</b>'
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Назва НП</th>
                        <td class="pl-2">${
        feature.properties.Name_NP !== null
          ? this.autolinker.link(
            feature.properties.Name_NP.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Тип НП</th>
                        <td class="pl-2">${
        feature.properties.Type_NP !== null
          ? this.autolinker.link(
            feature.properties.Type_NP.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                </table>`
      layer.bindPopup(popupContent, { maxHeight: 400 })
    },
    pop_Rayon_5 (feature, layer) {
      layer.on({
        mouseout: function (e) {
          for (var i in e.target._eventParents) {
            e.target._eventParents[i].resetStyle(e.target)
          }
        },
        mouseover: this.highlightFeature
      })
      const popupContent = `<table>
                    <tr>
                        <th scope="row">Область</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_1 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_1.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Район</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_2 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_2.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Тергромада</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_3 !== null
          ? '<b>' +
          this.autolinker.link(
            feature.properties.ADMIN_3.toLocaleString()
          ) +
          '</b>'
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Назва НП</th>
                        <td class="pl-2">${
        feature.properties.Name_NP !== null
          ? this.autolinker.link(
            feature.properties.Name_NP.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Тип НП</th>
                        <td class="pl-2">${
        feature.properties.Type_NP !== null
          ? this.autolinker.link(
            feature.properties.Type_NP.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                </table>`
      layer.bindPopup(popupContent, { maxHeight: 400 })
    },
    pop_Oblast_6 (feature, layer) {
      layer.on({
        mouseout: function (e) {
          for (var i in e.target._eventParents) {
            e.target._eventParents[i].resetStyle(e.target)
          }
        },
        mouseover: this.highlightFeature
      })
      var popupContent = `<table>
                    <tr>
                        <th scope="row">Область</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_1 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_1.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Район</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_2 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_2.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Тергромада</th>
                        <td class="pl-2">${
        feature.properties.ADMIN_3 !== null
          ? '<b>' +
          this.autolinker.link(
            feature.properties.ADMIN_3.toLocaleString()
          ) +
          '</b>'
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Назва НП</th>
                        <td class="pl-2">${
        feature.properties.Name_NP !== null
          ? this.autolinker.link(
            feature.properties.Name_NP.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                    <tr>
                        <th scope="row">Тип НП</th>
                        <td class="pl-2">${
        feature.properties.Type_NP !== null
          ? this.autolinker.link(
            feature.properties.Type_NP.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                </table>`
      layer.bindPopup(popupContent, { maxHeight: 400 })
    },
    pop_Capital_7 (feature, layer) {
      layer.on({
        mouseout: function (e) {
          for (var i in e.target._eventParents) {
            e.target._eventParents[i].resetStyle(e.target)
          }
        },
        mouseover: this.highlightFeature
      })
      const popupContent = `<table>
                    <tr>
                        <td class="pl-2">${
        feature.properties.ADMIN_1 !== null
          ? this.autolinker.link(
            feature.properties.ADMIN_1.toLocaleString()
          )
          : ''
      }</td>
                    </tr>
                </table>`
      layer.bindPopup(popupContent, { maxHeight: 400 })
    },
    linkSettlementsHandler (wrapper, data, layer) {
      const link = wrapper.querySelector('.link')
      if (link) {
        link.addEventListener(
          'click',
          function () {
            this.initSettlements(data)
            this.currentLayer = layer
            this.$router
              .replace({ query: { selectedFeature: data.ID } })
              .catch(() => {
              })
          }.bind(this)
        )
      }
    },
    initSettlements (data) {
      this.modal = false
      this.modalData = null
      this.searchSettlements = ''
      this.fetchSettlements(data.COD_3).then(() => {
        data.settlements = this.getSettlements
        this.settlements = [...data.settlements]
        this.modalData = data
        if (this.isAuthed) {
          this.getSettlements.forEach((el) => {
            if (el.rent) {
              this.checkedSettlements.rent[el.id] = el.rent
            }
            if (el.sell) {
              this.checkedSettlements.sell[el.id] = el.sell
            }
            if (el.stat) {
              this.checkedSettlements.stat[el.id] = el.stat
            }
          })
        }
        this.modal = true
      })
    },
    style_ADMIN_3_1_0 (feature) {
      switch (String(feature.properties.ADMIN_1)) {
        case 'Автономна Республіка Крим':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(255,255,204,0.4)',
            interactive: true
          }
        case 'Вінницька':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(222,203,228,0.4)',
            interactive: true
          }
        case 'Волинська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(204,235,197,0.4)',
            interactive: true
          }
        case 'Дніпропетровська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(251,180,174,0.4)',
            interactive: true
          }
        case 'Донецька':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(255,255,204,0.4)',
            interactive: true
          }
        case 'Житомирська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(255,255,204,0.4)',
            interactive: true
          }
        case 'Закарпатська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(179,205,227,0.4)',
            interactive: true
          }
        case 'Запорізька':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(254,217,166,0.4)',
            interactive: true
          }
        case 'Івано-Франківська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(229,216,189,0.4)',
            interactive: true
          }
        case 'Київська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(251,180,174,0.4)',
            interactive: true
          }
        case 'Кіровоградська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(179,205,227,0.4)',
            interactive: true
          }
        case 'Луганська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(179,205,227,0.4)',
            interactive: true
          }
        case 'Львівська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(222,203,228,0.4)',
            interactive: true
          }
        case 'Миколаївська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(204,235,197,0.4)',
            interactive: true
          }
        case 'Одеська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(229,216,189,0.4)',
            interactive: true
          }
        case 'Полтавська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(222,203,228,0.4)',
            interactive: true
          }
        case 'Рівненська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(254,217,166,0.4)',
            interactive: true
          }
        case 'Сумська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(204,235,197,0.4)',
            interactive: true
          }
        case 'Тернопільська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(251,180,174,0.4)',
            interactive: true
          }
        case 'Харківська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(229,216,189,0.4)',
            interactive: true
          }
        case 'Херсонська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(222,203,228,0.4)',
            interactive: true
          }
        case 'Хмельницька':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(179,205,227,0.4)',
            interactive: true
          }
        case 'Черкаська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(254,217,166,0.4)',
            interactive: true
          }
        case 'Чернівецька':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(254,217,166,0.4)',
            interactive: true
          }
        case 'Чернігівська':
          return {
            pane: 'pane_ADMIN_3_1',
            opacity: 1,
            color: 'rgba(141,141,141,1.0)',
            dashArray: '',
            lineCap: 'butt',
            lineJoin: 'miter',
            weight: 1.0,
            fill: true,
            fillOpacity: 1,
            fillColor: 'rgba(229,216,189,0.4)',
            interactive: true
          }
      }
    },
    style_ADMIN_2_2_0 () {
      return {
        pane: 'pane_ADMIN_2_2',
        opacity: 0.7,
        color: 'rgba(100,100,100,1.0)',
        dashArray: '',
        lineCap: 'square',
        lineJoin: 'bevel',
        weight: 2.0,
        fillOpacity: 0,
        interactive: false
      }
    },
    style_ADMIN_1_3_0 () {
      return {
        pane: 'pane_ADMIN_1_3',
        opacity: 1,
        color: 'rgba(77,77,77,1.0)',
        dashArray: '',
        lineCap: 'square',
        lineJoin: 'bevel',
        weight: 3.5,
        fillOpacity: 0,
        interactive: false
      }
    },
    style_Tergromada_4_0 () {
      return {
        pane: 'pane_Tergromada_4',
        radius: 3.599999999999998,
        opacity: 1,
        color: 'rgba(35,35,35,1.0)',
        dashArray: '',
        lineCap: 'butt',
        lineJoin: 'miter',
        weight: 1.0,
        fill: true,
        fillOpacity: 1,
        fillColor: 'rgba(255,255,255,1.0)',
        interactive: true
      }
    },
    /*extraFuncTergromada (feature, latlng) {
      return L.circleMarker(latlng, this.style_Tergromada_4_0(feature))
    },*/
    style_Rayon_5_0 () {
      return {
        pane: 'pane_Rayon_5',
        radius: 7.6,
        opacity: 1,
        color: 'rgba(0,0,0,1.0)',
        dashArray: '',
        lineCap: 'butt',
        lineJoin: 'miter',
        weight: 1.0,
        fill: true,
        fillOpacity: 1,
        fillColor: 'rgba(255,255,255,1.0)',
        interactive: true
      }
    },
    extraFuncRayon_5_0 (feature, latlng) {
      return L.circleMarker(latlng, this.style_Rayon_5_0(feature))
    },
    extraFuncRayon_5_1 (feature, latlng) {
      return L.circleMarker(latlng, this.style_Rayon_5_1(feature))
    },
    style_Rayon_5_1 () {
      return {
        pane: 'pane_Rayon_5',
        radius: 2.4625,
        opacity: 1,
        color: 'rgba(0,0,0,1.0)',
        dashArray: '',
        lineCap: 'butt',
        lineJoin: 'miter',
        weight: 2.0,
        fill: true,
        fillOpacity: 1,
        fillColor: 'rgba(0,0,0,1.0)',
        interactive: true
      }
    },
    style_Oblast_6_0 () {
      return {
        pane: 'pane_Oblast_6',
        radius: 11.199999999999998,
        opacity: 1,
        color: 'rgba(0,0,0,1.0)',
        dashArray: '',
        lineCap: 'butt',
        lineJoin: 'miter',
        weight: 2.0,
        fill: true,
        fillOpacity: 1,
        fillColor: 'rgba(255,255,255,1.0)',
        interactive: true
      }
    },
    style_Oblast_6_1 () {
      return {
        pane: 'pane_Oblast_6',
        radius: 4.0,
        opacity: 1,
        color: 'rgba(35,35,35,1.0)',
        dashArray: '',
        lineCap: 'butt',
        lineJoin: 'miter',
        weight: 2.0,
        fill: true,
        fillOpacity: 1,
        fillColor: 'rgba(255,255,255,1.0)',
        interactive: true
      }
    },
    extraFuncOblast_6_0 (feature, latlng) {
      return L.circleMarker(latlng, this.style_Oblast_6_0(feature))
    },
    extraFuncOblast_6_1 (feature, latlng) {
      return L.circleMarker(latlng, this.style_Oblast_6_1(feature))
    },
    style_Capital_7_0 () {
      return {
        pane: 'pane_Capital_7',
        shape: 'diamond',
        radius: 16.000000000000004,
        opacity: 1,
        color: 'rgba(35,35,35,1.0)',
        dashArray: '',
        lineCap: 'butt',
        lineJoin: 'miter',
        weight: 3.0,
        fill: true,
        fillOpacity: 1,
        fillColor: 'rgba(255,255,255,1.0)',
        interactive: true
      }
    },
    style_Capital_7_1 () {
      return {
        pane: 'pane_Capital_7',
        shape: 'diamond',
        radius: 6.609523999999999,
        opacity: 1,
        color: 'rgba(0,0,0,1.0)',
        dashArray: '',
        lineCap: 'butt',
        lineJoin: 'miter',
        weight: 2.0,
        fill: true,
        fillOpacity: 1,
        fillColor: 'rgba(255,255,255,1.0)',
        interactive: true
      }
    },
    extraFuncCapital_7_0 (feature, latlng) {
      return L.shapeMarker(latlng, this.style_Capital_7_0(feature))
    },
    extraFuncCapital_7_1 (feature, latlng) {
      return L.shapeMarker(latlng, this.style_Capital_7_1(feature))
    },
    createControlToggle (iconClass, title, handler) {
      const a = document.createElement('a')
      a.className = 'control leaflet-control cursor-pointer'
      a.title = title
      const icon = document.createElement('i')
      icon.className = `mdi ${iconClass}`
      a.append(icon)
      a.addEventListener('click', () => {
        handler()
      })
      return a
    },
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      this.beforeInitializationTables()
    },
    windowOpen (path, type = '_blank') {
      window.open(`${location.origin}/${path}`, type)
    }
  },
  async mounted () {
    this.isLoading = true
    this.onResize()
    if (localStorage.checkedSettlements) {
      this.checkedSettlements = JSON.parse(localStorage.checkedSettlements)
    }
    if (localStorage.selectedSettlements) {
      this.selectedSettlements = JSON.parse(localStorage.selectedSettlements)
    }
    if (this.$cookies.get('_token')) {
      await this.fetchSubscribes()
    }
    this.setupLeafletMap()
    this.map.whenReady(() => {
      const leafletControlLayers = document.querySelector(
        '.leaflet-control-layers'
      )
      const parent = leafletControlLayers.parentElement
      const parentOfControls = document.createElement('div')
      parentOfControls.className = 'parent-of-controls'
      parent.className += ' d-flex flex-column'
      const auction = this.createControlToggle(
        'mdi-calendar-multiple-check',
        'Активні аукціони',
        () => this.windowOpen('auction')
      )
      parentOfControls.append(auction)
      const stat = this.createControlToggle('mdi-chart-bar', 'Статистика', () =>
        this.windowOpen('statistic-land')
      )
      parentOfControls.append(stat)
      const subscribes = this.createControlToggle(
        'mdi-format-list-checks',
        'Налаштування',
        () => this.windowOpen('subscribes')
      )
      parentOfControls.append(subscribes)
      parent.append(parentOfControls)
      if (this.$route.query.selectedFeature) {
        this.initSettlements(
          ADMIN_3_1.features[this.$route.query.selectedFeature].properties
        )
      }
      this.isLoading = false
    })
  },
  beforeDestroy () {
    //destroy map and remove layers
    this.map.remove()
    this.bounds_group.clearLayers()
    this.map.off()
    this.currentLayer = null
    this.checkedSettlements = this.selectAll = this.settlements = {}
    this.selectedSettlements = []
  }
}
