<template>
  <v-dialog v-model="open" :width="width" :scrollable="scrollable">
    <v-card>
      <v-card-title class="lighten-2">
        <slot name="title" />
        <v-icon class="close-btn pa-2" @click="closeModal">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <slot name="body" />
      </v-card-text>
      <v-card-actions class="d-flex justify-end pr-6 pl-6">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    width: {
      default: '500'
    },
    dialog: {
      type: Boolean,
      default: false
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    dialog (val) {
      this.open = val
    },
    open (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  data: () => ({
    open: false
  }),
  methods: {
    closeModal () {
      this.open = false
    }
  }
}
</script>

<style lang="scss">
.v-dialog {
  &--active {
    overflow-x: hidden;
  }
  .close-btn {
    position: absolute;
    right: 0;
    top: 10px
  }
}
</style>
